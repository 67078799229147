import React from "react";
import "./topselling.css";
import 'bootstrap/dist/css/bootstrap.min.css';
const Topseling=()=>{
   
      
    return(
        <div className="container ">
     <h1
  className="text-white ml-5"
  style={{
    marginLeft: '3%',
    position: 'relative',
    display: 'inline-block',
    fontSize: window.innerWidth <= 600 ? '1.2rem' : '2rem', // Adjust font size based on screen width
  }}
>
  Top SELLERS&nbsp;
  <span
      className="responsive-span"
      style={{
        position: 'absolute',
        bottom: 0,
        left: '100%',
        width: '100%',
        height: '3px',
        backgroundColor: '#ea002a',
      }}
    ></span>
</h1>

      
      <div className="row text-center mb-5 mt-5">
                {/* First cards */}
      <div className="col-12 col-sm-6 col-md-3 mb-4 ">
        <a href="#" className="__card">
          <button className="__favorit">
            <i className="la la-heart-o"></i>
          </button>
          <div className="position-relative ">
            <img
              src="https://www.kfcpakistan.com/images/b438e990-bc23-11ee-be0d-ed0e61ce8a3a-Untitleddesign(5)-min_variant_0-2024-01-26082002.png"
              className="img-fluid __img border border-danger"
              alt="Product"
            />
            <div
              className="position-absolute top-0 start-0 bg-danger text-white p-2 rounded "
              style={{ margin: '10px' }}
            >
              $20.99
            </div>
          </div>
          <div className="__card_detail text-left ali">
            <h4 className="text-center ">Krunch Burger</h4>
          </div>
        </a>
      </div>

      {/* Repeat the same structure for each of the four cards */}
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <a href="#" className="__card">
          <button className="__favorit">
            <i className="la la-heart-o"></i>
          </button>
          <div className="position-relative">
            <img
              src="https://www.kfcpakistan.com/images/43a9fb50-ffaa-11ed-8180-812e571998fe-crunch-with-fries-and-drink-2023-05-31115706.png"
              className="img-fluid __img border border-danger"
              alt="Product"
            />
            <div
              className="position-absolute top-0 start-0 bg-danger text-white p-2 rounded"
              style={{ margin: '10px' }}
            >
              $20.99
            </div>
          </div>
          <div className="__card_detail text-left">
            <h4 className="text-center no-text-decoration">Krunch Burger</h4>
          </div>
        </a>
      </div>

      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <a href="#" className="__card">
          <button className="__favorit">
            <i className="la la-heart-o"></i>
          </button>
          <div className="position-relative">
            <img
              src="https://www.kfcpakistan.com/images/33685b40-0461-11ee-911c-497570899609-Mighty_variant_0-2023-06-06115641.png"
              className="img-fluid __img border border-danger"
              alt="Product"
            />
            <div
              className="position-absolute top-0 start-0 bg-danger text-white p-2 rounded"
              style={{ margin: '10px' }}
            >
              $20.99
            </div>
          </div>
          <div className="__card_detail text-left">
            <h4 className="text-center no-text-decoration">Krunch Burger</h4>
          </div>
        </a>
      </div>

      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <a href="#" className="__card">
          <button className="__favorit">
            <i className="la la-heart-o"></i>
          </button>
          <div className="position-relative">
            <img
              src="https://www.kfcpakistan.com/images/38718ee0-bc17-11ee-97ca-ad9c0958c4fc-Plain-wings-min_variant_0-2024-01-26065041.png"
              className="img-fluid __img border border-danger"
              alt="Product"
            />
            <div
              className="position-absolute top-0 start-0 bg-danger text-white p-2 rounded"
              style={{ margin: '10px' }}
            >
              $20.99
            </div>
          </div>
          <div className="__card_detail text-left">
            <h4 className="text-center no-text-decoration">Krunch Burger</h4>
          </div>
        </a>
      </div>
    </div>

        </div>
    )
}
export default Topseling;