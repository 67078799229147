import { Link, useNavigate } from "react-router-dom";

function SignUp() {
  const navigate = useNavigate();

  return (
    <div className="pt-[5rem] container" >
    <div id="app" className="app app-full-height app-without-header pb-5">
      <div className="register">
        <div className="register-content">
          <form className="d-flex flex-column justify-center align-items-center">
            <h1 className="text-center mt-3" style={{ color: "black" }}>Sign Up</h1>
  
            <div className="mb-3  col-12 col-md-6 col-lg-4" >
              <label className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                placeholder="e.g John Smith"
              />
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Email Address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control form-control-lg bg-white bg-opacity-5"
                placeholder="username@address.com"
              />
            </div>
  
            <div className="mb-3  col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control form-control-lg bg-white bg-opacity-5"
              />
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Confirm Password <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control form-control-lg bg-white bg-opacity-5"
              />
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Country <span className="text-danger">*</span>
              </label>
              <select className="form-select form-select-lg bg-white bg-opacity-5">
                <option>United States</option>
              </select>
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Gender <span className="text-danger">*</span>
              </label>
              <select className="form-select form-select-lg bg-white bg-opacity-5">
                <option>Female</option>
              </select>
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <label className="form-label">
                Date of Birth <span className="text-danger">*</span>
              </label>
              <div className="row gx-2">
                <div className="col-12 col-sm-4">
                  <select className="form-select form-select-lg bg-white bg-opacity-5">
                    <option>Month</option>
                  </select>
                </div>
                <div className="col-12 col-sm-4">
                  <select className="form-select form-select-lg bg-white bg-opacity-5">
                    <option>Day</option>
                  </select>
                </div>
                <div className="col-12 col-sm-4">
                  <select className="form-select form-select-lg bg-white bg-opacity-5">
                    <option>Year</option>
                  </select>
                </div>
              </div>
            </div>
  
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="customCheck1"
                />
                <label className="form-check-label " htmlFor="customCheck1">
                  I have read and agree to the{" "}
                  <a href="/" style={{ color: "#e75300" }}>
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href="/" style={{ color: "#e75300" }}>
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
            </div>
  
            <div className="mb-3 w-100">
              <button
                type="submit"
                className="btn btn-outline-theme btn-lg d-block w-100"
              >
                Sign Up
              </button>
            </div>
  
            <div className="text-inverse text-opacity-50 text-center">
              Already have an Admin ID?{" "}
              <a href="/login" style={{ color: "#e75300" }}>
                Sign In
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default SignUp;
