import React from "react";
import { data } from "../../data/data.js";
import { Link, Outlet } from "react-router-dom";
import { apiUrl } from "../../data/env.js";

const Categories = ({ categories }) => {
  return (
    <div>
      <h1 className="text-white ml-5" style={{ marginLeft: '10%', position: 'relative', display: 'inline-block' }}>
        Food Categories&nbsp;
        <span
      className="responsive-span"
      style={{
        position: 'absolute',
        bottom: 0,
        left: '100%',
        width: '100%',
        height: '3px',
        backgroundColor: '#ea002a',
      }}
    ></span>
      </h1>
      {/* container for projects */}
      <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4 m-4">
        
        {categories?.slice(0,8).map((item, index) => (
          <div key={index} class="d-flex flex-col justify-center items-center">
            <Link
              to={`/productCategories/${item._id}`}
              class="text-black no-underline"
            >
              <img
                src={`${apiUrl}/images/categories/${item.image}`}
                alt=""
                class="w-[180px] h-[180px] border-red-500 rounded-full border-2"
              />

              <h4 className="text-center text-white font-semibold pt-2 uppercase hover:text-red no-underline">
                {item.name}
              </h4>
              <p class="text-slate-600 text-center  font-semibold no-underline">
                {item.desc}
              </p>
            </Link>
          </div>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default Categories;
