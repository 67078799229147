import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { LuShoppingCart } from "react-icons/lu";
import { Badge } from "antd";
import { IoIosSearch } from "react-icons/io";
import "./Navigation.css";
const Navigation_Bar = () => {
  const [navSize, setnavSize] = useState("4rem");
  const [topNav, setTopNav] = useState(40);
  const [navColor, setnavColor] = useState("transparent");

  const [commodityCount, setCommodityCount] = useState(0);

  const commodityList = JSON.parse(localStorage.getItem('commodityList')) || [];
  useEffect(() => {
    // Retrieve commodityList from localStorage

    // Calculate the size of the array
    const count = commodityList.length;

    // Update the state with the count
    setCommodityCount(count);
  }, [commodityList]);



  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("4rem");
    window.scrollY > 10 ? setTopNav(0) : setTopNav(0);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <div className=" ">
      <nav
        className="fixed w-100 mx-0 drop-shadow-md "
        style={{
          backgroundColor: navColor,
          height: navSize,
          zIndex: "2",
        }}
      >
        <Navbar
          collapseOnSelect
          expand="lg"
          sticky="top"
          class="p-5 "
          className="bg-black   drop-shadow-sm "
        >
          <Container className="p-2 ">
      <Navbar expand="lg" className="navbar-dark  ">
        <Navbar.Brand href="/">
          <img
            src="https://ik.imagekit.io/tdyftai86/Clietns/withour%20BG.png?updatedAt=1729010713794"
            width={100}
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle style={{marginLeft:"100px"}} aria-controls="responsive-navbar-nav" />
 
        <Navbar.Collapse   id="responsive-navbar-nav">
        <Nav className="khuram px-5 text-sm gap-5 font-sans d-flex justify-content-center align-items-center w-100"
  style={{ flexWrap: "nowrap",   }} // Prevent wrapping on all screen sizes
>
  <Link
    to="/"
    className="no-underline text-white hover:text-amber-500 relative"
    style={{ fontWeight: "bolder" }}
  >
    HOME{" "}
    <span
      className="absolute text-white hidden md:block"
      style={{ marginTop: -21, marginLeft: 60 }}
    >
      |
    </span>{" "}
  </Link>
  <Link
    to="/"
    className="no-underline text-white hover:text-amber-500 relative"
    style={{ fontWeight: "bolder" }}
  >
    MENU{" "}
    <span
      className="absolute text-white hidden md:block"
      style={{ marginTop: -21, marginLeft: 60 }}
    >
      |
    </span>{" "}
  </Link>
  <Link
    to="/blogmain"
    className="no-underline text-white hover:text-amber-500 relative"
    style={{ fontWeight: "bolder" }}
  >
    BLOG{" "}
    <span
      className="absolute text-white hidden md:block"
      style={{ marginTop: -21, marginLeft: 60 }}
    >
      |
    </span>{" "}
  </Link>
  <Link
    to="/contactus"
    className="no-underline text-white hover:text-amber-500 relative"
    style={{ fontWeight: "bolder" }}
  >
    CONTACT US{" "}
  </Link>
</Nav>

<Nav className=" al px-5 gap-3 d-flex justify-content-end align-items-center">
  <Badge
    count={commodityCount}
    color="#ea002a"
    showZero
    className="d-none d-md-block"
  >
    <span className="text-2xl text-secondary">
      <Link
        to="/cartview"
        className="text-[#fcfcfc] hover:text-[#444444]"
      >
        <LuShoppingCart />
      </Link>
    </span>
  </Badge>

  <h6 className="text-2xl text-secondary d-none d-md-block">
    <Link to="/login" className="text-[#fcfcfc] hover:text-[#444444]">
      <AiOutlineUser />
    </Link>
  </h6>
</Nav>

</Navbar.Collapse>

      </Navbar>
    </Container>
        </Navbar>
      </nav>
    </div>
  );
};

export default Navigation_Bar;
