import React from "react";
import Landing_Image from "../Sub_Components/Page1/Landing_Image";
import Categories from "../Sub_Components/Page1/Categories";
import Blog from "../Sub_Components/Page1/Blog";
import Topseling from "../Sub_Components/Page1/Topselling";

const Home = ({ categories }) => {
  return (
    <div className="bg-black">
      <Landing_Image />
      <Categories categories={categories} />
      <Topseling/>
      <Blog />
    </div>
  );
};

export default Home;
