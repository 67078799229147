import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate()


  return (
    <div className="bg-zinc-800 font-sans text-white ">
      <Container>
        <Row className="p-4">
          <Col md={3} sm={12}>
            <img
              className="mt-3"
              src="https://ik.imagekit.io/b6iqka2sz/zoya_chore-removebg-preview.png?updatedAt=1725014737240"
              width={200}
            />
            <p className="mt-3 leading-5 ">
              Welcome to our Foodelicious, where culinary magic meets
              exceptional dining. Explore a world of flavors, indulge in
              delectable creations, and embark on a gastronomic journey like no
              other. Discover your new food paradise!
            </p>
          </Col>
          <Col md={2} sm={12} className=" flex row">
            <p className="text-center font-sans leading-loose mt-4">
              <h6 className="lg:ml-[33px] " style={{cursor: 'pointer'}}>SERVICE</h6>
              <span className="lg:ml-[15px]" style={{cursor: 'pointer'}} onClick={() => navigate("/")}>Home</span> <br />
              <span className="lg:ml-[15px]" style={{ cursor: 'pointer' }} onClick={() => navigate("/")}>Menu</span>
              <br />
              <span className="lg:ml-[4px]" style={{ cursor: 'pointer' }} onClick={() =>navigate('/blogmain')}>Blog</span> <br />
              <span className="lg:ml-[29px]" style={{ cursor: 'pointer' }}>About Us </span>
              <br />
              <span className="lg:ml-[33px]" style={{ cursor: 'pointer' }} onClick={() => navigate("/contactus")}>Contact Us</span> <br />
            </p>
          </Col>
          <Col md={4} sm={12} className="flex row ">
            <p className="text-center font-sans  mt-4">
              <h6 className="lg:ml-[-37px] ">CONTACT US</h6>
              <p className="lg:ml-[88px] flex mt-4 col">
                <h5>
                  <IoLocationOutline />
                </h5>
                <span className="ml-2" style={{ fontSize: 12 }}>
                  80 EAST STREET BEDMINSTER,BS34EY
                </span>
              </p>

              <p className="lg:ml-[88px]  flex  col">
                <h5>
                  <FiPhone />
                </h5>
                <span className="text-sm ml-2 leading-5">0117 985 1147yy</span>
              </p>
            </p>
          </Col>
          <Col md={3} sm={12} className="">
            <p className="text-center font-sans  mt-5">
              <h5 className="lg:ml-[77px]">GET NEW & OFFER</h5>
              <p className="lg:ml-[78px] ">
                {/* <InputGroup className="mt-3" style={{ width: 290 }}>
                  <Form.Control
                    placeholder="Email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    variant="outline-secondary text-white"
                    id="button-addon2"
                  >
                    Subscribe
                  </Button>
                </InputGroup> */}
              </p>
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid>
      <Row className="bg-dark rounded justify-content-center align-items-center" >
  <Col className="pt-3 text-center">
    <p className="text-sm px-5 text-white ">Copyright 2024 Designed By :<span className="text-danger fw-bolder "> Webtime Solution LTD</span></p>
  </Col>
</Row>

      </Container>
    </div>
  );
};

export default Footer;
