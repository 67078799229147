import React from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
const Landing_Image = () => {
  return (
    <div className='mb-5 ' >
      <Container fluid>
        <Row>
          <Container fluid>
            <Row className='mt-5' >
              <Carousel interval={3000} pause={false} style={{marginTop:"30px"}}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    style={{ height: 'auto', maxHeight: '400px' }}
                    src="https://www.kfcpakistan.com/images/2bc5d940-3b00-11ef-a6d3-a91706ef6866-995x356copy_desktop_image-2024-07-05185539.jpg"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    style={{ height: 'auto', maxHeight: '400px' }}
                    src="https://www.kfcpakistan.com/images/6d17d1f0-49c4-11ef-8581-bb135cf642fe-995x356_desktop_image-2024-07-24135546.jpg"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    style={{ height: 'auto', maxHeight: '400px' }}
                    src="https://www.kfcpakistan.com/images/a4214300-f29b-11ee-84d7-dbdd7f31cafb-1copy_desktop_image-2024-04-04155438.jpg"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  )
}

export default Landing_Image
