import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from "../../Assets/BlogImg.jpg"
const Blog = () => {
  return (
    <div>
      {/* Why Choose */}
      <div className="container">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col ">
          <div class="card">
            <img src="https://www.kfcpakistan.com/static/media/pickup-banner.01fde5a7db5ef06f7bce.png" class="card-img-top" alt="..." />

          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src="https://www.kfcpakistan.com/static/media/favorites-banner.afff26bc6d0127f68baa.png" class="card-img-top" alt="..." />

          </div>
        </div>
        <div class="col mb-5">
          <div class="card">
            <img src="https://www.kfcpakistan.com/static/media/scamOrdr.6f809bf83912dd8dbe31.jpg" class="card-img-top" alt="..." />

          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src="https://www.kfcpakistan.com/static/media/scamOrdr.6f809bf83912dd8dbe31.jpg" class="card-img-top" alt="..." />

          </div>
        </div>
      </div>
    </div>

    

      {/* contact */}
      <Container fluid class="p-0 ">
        <Row>
          {" "}
          <Col
            sm={12}
            md={6}
            lg={6}
            className="p-5 m-0  bg-[#1c1816] d-flex flex-col justify-center items-center "
          >
            <h1 className="text-white  font-sans font-bold text-center">
              Visit Us
            </h1>
            <div class="d-flex justify-center items-center mx-2">
              <img
                src="https://ik.imagekit.io/2nuimwatr/Component%201.png?updatedAt=1701851731230"
                alt=""
                class="mx-2"
              />
              <b class="text-white">
                For Bookings & Other Queries, Call Us at:
              </b>
            </div>

            <h4 class="py-2 text-[#ea002a]">0117 985 1147</h4>
            <div class="d-flex justify-center items-center mx-2">
              <img
                src="https://ik.imagekit.io/2nuimwatr/Component%201%20(1).png?updatedAt=1701851908697"
                alt=""
                class="mx-2"
              />
              <b class="text-white">Location</b>
            </div>
            <p class="px-3 mx-3 text-white">80 EAST STREET BEDMINSTER, BS3 4EY</p>
            <div class="d-flex justify-center items-center mx-2">
              <img
                src="https://ik.imagekit.io/2nuimwatr/Component%202.png?updatedAt=1701851908726"
                alt=""
                class="mx-2"
              />
              <b class="text-white">Open Time</b>
            </div>
            <p class="px-3 text-white">Monday to Sunday: 03:00 pm - 10:00 pm</p>

            {/* <p class="px-3">Saturday to Sunday: 6:00 am - 11:00 pm</p> */}
          </Col>
          <Col sm={12} md={6} lg={6} className="p-0 m-0" data-aos="fade-up">
            <iframe
              class="w-100 h-100"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.7208009586145!2d-2.5976303!3d51.4419388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48718c2afe0462c9%3A0x6a18b3aedfe9e621!2s80-82%20East%20St%2C%20Bedminster%2C%20Bristol%20BS3%204EY%2C%20UK!5e0!3m2!1sen!2s!4v1702567683578!5m2!1sen!2s"
              frameBorder={0}
              allowFullScreen
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Blog;