import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import "../../App.css";

function SelectOptionPage({ currentOptions, currentItem, setCommodityList }) {
  const navigate = useNavigate();
  const { itemId } = useParams();
  console.log(currentItem, "curItem");
  console.log(currentOptions, "curOpts");
  const [stepsPrice, setStepsPrice] = useState(currentItem.price);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [AddInCart, setAddInCart] = useState(false);

  const [topping, setTopping] = useState("10'INCHES");
  const [cheese, setCheese] = useState("Yes");
  const [meat, setMeat] = useState("Yes");

  const onOptionChange = (e) => {
    setTopping(e.target.value);
  };
  const onOptionChange2 = (e) => {
    setCheese(e.target.value);
  };
  const onOptionChange3 = (e) => {
    setMeat(e.target.value);
  };

  const getCheckedOptions = () => {
    console.log(document.querySelectorAll('input[type="checkbox"]:checked'));
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    let arrMain = [];
    let choosedOptions = [];
   


    const checkedCheckboxes = document.querySelectorAll("input[type=checkbox]:checked");
    if (checkedCheckboxes.length === 0) {
      // If no checkboxes are selected, add the product directly to the cart
      choosedOptions.push({
        stepName: "Added 1",
        shortName: "Successfully added",
        type: " ",
        price: 0, // Set a default price or leave it as 0
        selected: true,
        optId: "no_options_id",
      });
    }else{
      document
        .querySelectorAll("input[type=checkbox]:checked")
        .forEach((inp) => arrMain.push(inp.id));

      currentOptions.stepsToChoose?.forEach((step) =>
        step.options.forEach((opt) => {
          if (arrMain.includes(opt._id)) {
            choosedOptions.push({
              stepName: step.stepName,
              shortName: step.shortName,
              type: opt.type,
              price: opt.price,
              selected: true,
              optId: opt._id,
            });
          }
        })
      );
    }

    const tempCommodity = {
      barcode: "testBarcode",
      name: currentItem.name,
      productPrice: stepsPrice,
      options: choosedOptions,
      unit: 1,
    };
    if (currentItem.steps) tempCommodity.stepsId = currentItem.steps;

    setDisplayOptions(filterArray(tempCommodity.options));
    setAddInCart(true);
    
    console.log("tempComm: =============", tempCommodity);

    setCommodityList((comm) => {
      // Retrieve existing commodity list from local storage
      const existingCommodities = JSON.parse(localStorage.getItem("commodityList")) || [];

      // Check if the commodity already exists in the local storage based on name
      const existingIndex = existingCommodities.findIndex(item => item.name === tempCommodity.name);
      console.log("existingCommodities: =============", existingCommodities);
      console.log("existingIndex: =============", existingIndex);

      if (existingIndex !== -1) {
        // If the commodity already exists, update it
        existingCommodities[existingIndex] = tempCommodity;
        localStorage.setItem("commodityList", JSON.stringify(existingCommodities));
        return existingCommodities;
      } else {
        // If the commodity doesn't exist, add it to the local storage
        const updatedCommodities = [...comm, tempCommodity];
        localStorage.setItem("commodityList", JSON.stringify(updatedCommodities));
        return updatedCommodities;
      }
    }); 
  };




  

  const filterArray = (inputArray) => {
    return inputArray.reduce((acc, current) => {
      const existingItem = acc.find(
        (item) => item.stepName === current.stepName
      );
      if (existingItem) {
        existingItem.type.push(current.type);
      } else {
        acc.push({
          ...current,
          type: [current.type],
        });
      }

      return acc;
    }, []);
  };

  return (
    <div class="pt-[5rem] pb-[5rem] bg-black">
      <div class="">
        <h3 className="text-center text-white font-bold">Options</h3>
        <Container
  className="card container p-5 mt-4 mb-5 font-sans"
  style={{ backgroundColor: "#1c1816" }}
>
  <Row>
  <Col xs={12} sm={12} md={8} lg={8} className="p-3">
  <div className="border-r-2 border-gray-400">
    <div style={{ color: "white", display: "flex", alignItems: "center" }}>
      <p style={{ cursor: "pointer", margin: 0 }}>
        <FaArrowLeftLong onClick={() => navigate(-1)} />
      </p>
      <h4
        className="font-bold text-white"
        style={{
          marginLeft: "15px",
          fontSize: "1.2rem",
        }}
      >
        {currentItem.name || " "}
      </h4>
    </div>
    <p style={{ marginLeft: "20px", fontSize: "0.9rem" }} className="text-white">
      {currentItem.description || " "}
    </p>
    {currentOptions?.stepsToChoose.map((step) => (
      <Step
        key={step._id}
        stepToChoose={step}
        setStepsPrice={setStepsPrice}
        getCheckedOptions={getCheckedOptions}
        handleAddToCart={handleAddToCart}
      />
    ))}
    <Button
      className="text-bold mt-3"
      onClick={handleAddToCart}
      variant="text-white"
      style={{
        marginTop: "20px",
        backgroundColor: "#ea002a",
        color:"white"
      }}
    >
      Add to Cart
    </Button>
  </div>
</Col>


    <Col xs={12} sm={12} md={4} lg={4}>
      <h4 className="text-center text-white mt-5">TOTAL </h4>
      {displayOptions?.map((opt) => {
        return (
          <>
            <p style={{ color: "white" }}>
              {opt.shortName}{" "}
              <strong>
                {opt.type.map((o) =>
                  opt.type.length === 1 ? o : o + ", "
                )}
              </strong>
            </p>
          </>
        );
      })}
      {AddInCart ? (
        <>
          <br />
          <h5 style={{ color: "white", fontWeight: "bolder" }}>
            Your Selected Items added into the cart
          </h5>
        </>
      ) : null}

      <div className="d-flex col mt-[150px] justify-between">
        <div>
          <p className=" text-xl font-semibold text-white"> Total </p>
        </div>

        <div>
          <p className=" text-xl font-semibold" style={{ color: "white" }}>
            £{Math.round(stepsPrice * 100) / 100}
          </p>
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-row w-100 gap-3 justify-content-center">
        <button
          onClick={() => navigate("/cartview")}
          className="text-white w-100 w-sm-50 p-2 rounded font-weight-bold"
          style={{ backgroundColor: "#ea002a" }}
        >
          Checkout
        </button>
        <button
          onClick={() => navigate(-1)}
          className="text-white w-100 w-sm-50 p-2 rounded font-weight-bold"
          style={{ backgroundColor: "#ea002a" }}
        >
          Continue Shopping
        </button>
      </div>
    </Col>
  </Row>
</Container>

      </div>
    </div>
  );
}

function Step({ stepToChoose, setStepsPrice, handleAddToCart }) {
  return (
    < div style={{backgroundColor:"#1c1816 !important"}}>
      <h6 className="mt-4 ml-[47px]  font-semibold  text-white">
        {stepToChoose.stepName}
      </h6>
      <div class="d-flex flex-col ml-[47px]   justify-center items-start text-white">
        <Option
          options={stepToChoose.options}
          stepName={stepToChoose.stepName}
          setStepsPrice={setStepsPrice}
        />
      </div>
    </div>
  );
}

function Option({ options, stepName, setStepsPrice }) {
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);

  const handleCheckboxChange = (value, stepNm, optPrice) => {
    let max = 1;
    if (stepNm.toLowerCase().includes("any"))
      max = Number(stepNm.replace(/[^0-9]/g, ""));
    // Check if the checkbox is already selected
    if (selectedCheckboxes.includes(value)) {
      // If selected, remove it from the array
      setStepsPrice(
        (stepsPrices) => Math.round((stepsPrices - optPrice) * 100) / 100
      );
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox !== value)
      );
    } else {
      // If not selected, check if the maximum limit is reached
      if (selectedCheckboxes.length < max) {
        // If not reached, add the checkbox to the array
        setStepsPrice(
          (stepsPrices) => Math.round((stepsPrices + optPrice) * 100) / 100
        );
        setSelectedCheckboxes([...selectedCheckboxes, value]);
      }
    }
  };

  return (
    <>
      {options.map((option) => {
        return (
          <div key={option._id} class="option-container bg-red">
            <input
              type="checkbox"
              name={option.type}
              value={option._id}
              id={option._id}
              checked={selectedCheckboxes.includes(option._id)}
              onChange={(e) => {
                handleCheckboxChange(option._id, stepName, option.price);
              }}
            />
            <label htmlFor={option._id} class="type-label px-2">
              {option.type}
            </label>
            <label htmlFor={option._id} class="price-label">
              £{option.price}
            </label>
          </div>

        );
      })}
    </>

  );
}

export default SelectOptionPage;
